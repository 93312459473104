import { IDesk, ISubTask, ITask, IUser } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useStore } from '@hooks/storeHook';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IAssigneSelector {
  onHide: () => void;
  setTask: (field: string, value: ITask[keyof ITask]) => void;
}

export const AssigneSelector = observer(
  ({ onHide, setTask }: IAssigneSelector) => {
    const [filter, setFilter] = useState('');
    const { chatInfo } = useStore();
    const { t } = useTranslation();
    const users = chatInfo?.users;

    const onChangeUser = (user?: IUser) => {
      onHide();
      if (user) {
        const newObj = [{ ...user }];
        setTask('responsible', newObj);
      } else {
        const newObj: string | number | ISubTask[] | IDesk | IUser[] = [];
        setTask('responsible', newObj);
      }
      extraLightHapticFeedback();
    };

    return (
      <>
        <div className=' px-[16px] py-[10px] mt-[15px] mb-[5px] min-h-[48px] max-h-[48px] w-full bg-[#F5F5F5] rounded-[12px] flex flex-row items-center'>
          <input
            value={filter}
            placeholder={t('input.namePlaceholder')}
            onChange={(e) => setFilter(e.target.value)}
            className='w-full mr-[10px] text-[17px] border-none bg-transparent rounded-full shadow-none appearance-none outline-none text-grey-text'
          />
          <div onClick={() => setFilter('')} className='cursor-pointer'>
            <CloseDrawerIcon size={24} />
          </div>
        </div>
        {users
          ?.filter((item) =>
            filter.length > 0
              ? item?.first_name
                  ?.toLowerCase()
                  .includes(filter.toLowerCase()) ||
                item?.last_name?.toLowerCase().includes(filter.toLowerCase())
              : item,
          )
          .map((user) => {
            const lastName = user.last_name ?? '';
            const firstName = user.first_name ?? '';
            const userName = lastName + ' ' + firstName;
            const initials =
              (lastName[0]?.toUpperCase() ?? '') +
              (firstName[0]?.toUpperCase() ?? '');
            return (
              <div
                onClick={() => onChangeUser(user)}
                className='w-full flex items-center gap-[7px] border-solid  border-0 border-border-grey border-b-[1px] py-[10px]'
                key={user.id}
              >
                {user.photo_url ? (
                  <img
                    src={user.photo_url}
                    className='object-contain  rounded-[100px] w-[32px] h-[32px] bg-grey'
                  />
                ) : (
                  <div className='w-[32px] h-[32px] text-base font-bold text-white rounded-[100px] bg-blue flex items-center justify-center'>
                    {initials}
                  </div>
                )}
                <span className='text-large font-medium'>{userName}</span>
                <span className='text-large text-blue ml-auto cursor-pointer'>
                  {t('button.assign')}
                </span>
              </div>
            );
          })}
      </>
    );
  },
);
