import { LoadState } from '@@types/loadState';
import { ReactNode, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutesPaths, setNavigate } from '@config/navigation';
import { useStore } from '@hooks/storeHook';
import { LoadIcon } from '@pages/icons/loadIcon';
import { useTranslation } from 'react-i18next';

export const RootLoader = observer(({ children }: { children: ReactNode }) => {
  const { rootLoading } = useStore();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setNavigate(navigate);
  }, []);

  if (
    rootLoading === LoadState.Success ||
    location.pathname === AppRoutesPaths.Landing
  ) {
    return <>{children}</>;
  }

  if (
    rootLoading === LoadState.Error &&
    location.pathname !== AppRoutesPaths.Landing
  ) {
    return (
      // @ts-ignore
      <div style={styles.container}>
        <span className='text-blue'>{t('error')}</span>
      </div>
    );
  }

  if (
    rootLoading === LoadState.Loading &&
    location.pathname !== AppRoutesPaths.Landing
  ) {
    return (
      //@ts-ignore
      <div style={styles.container}>
        <LoadIcon />
      </div>
    );
  } else return null;
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    gap: '24px',
  },
};
