import { isVersionValid } from './isTgVersionValid';

export const downloadFile = (
  fileName: string,
  taskId: number,
  fileId: string,
) => {
  const url =
    process.env.REACT_APP_BASE_URL + `tasks/${taskId}/files/${fileId}`;

  const webApp = window.Telegram?.WebApp;

  if (webApp && isVersionValid(webApp.version))
    webApp.downloadFile({ file_name: fileName, url: url });
  else {
    const a = document.createElement('a');
    a.href = url;
    a.target = '__blank';
    a.download = 'file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
