import { IChat } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { AppRoutesPaths, navigateTo } from '@config/navigation';
import { appColors, getRandomColor } from '@config/theme';
import { ArrowRight } from '@pages/icons/arrowRight';

import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import { action } from 'mobx';

export const ChatCard = ({ item }: { item: IChat }) => {
  const logic = useLogic();
  const store = useStore();

  const onclick = action(async () => {
    store.chat_id = item.id;
    await logic.switchProject();
    extraLightHapticFeedback();
    navigateTo(AppRoutesPaths.Boards);
  });

  return (
    <div
      onClick={onclick}
      style={{ background: darkTheme ? appColors.darkCardBackground : 'white' }}
      className='flex items-center gap-[20px] w-full rounded-[12px] h-[60px]  p-[15px] cursor-pointer'
    >
      <div
        style={{ backgroundColor: getRandomColor() }}
        className='w-[32px] h-[32px] text-biggerPlus font-bold text-white rounded-[100px] bg-red flex items-center justify-center'
      >
        {item.title[0].toUpperCase()}
      </div>
      <span
        style={{ color: darkTheme ? 'white' : 'black' }}
        className='text-large'
      >
        {item.title}
      </span>
      <div className='ml-auto h-[30px] flex justify-center items-center'>
        <ArrowRight />
      </div>
    </div>
  );
};
