import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { appColors } from '@config/theme';
import { useStore } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import { BoardCard } from '@pages/HomePage/BoardCard';
import { ChatCard } from '@pages/TaskPage/ChatCard';
import { SharingBar } from '@ui/SharingBar/SharingBar';
import { motion } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

export const TabComponent = ({
  items,
  className,
  styles,
}: {
  items: { name: string; render: ReactElement }[];
  className?: string;
  styles?: React.CSSProperties;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const store = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    extraLightHapticFeedback();
  }, [activeIndex]);

  const handlers = useSwipeable({
    onSwipedRight: () => setActiveIndex(0),
    onSwipedLeft: () => setActiveIndex(1),
    swipeDuration: 500,
    trackMouse: true,
  });

  return (
    <div style={styles} className={`w-full h-full flex flex-col ${className}`}>
      <div
        style={{
          background: darkTheme ? appColors.darkCardBackground : 'white',
        }}
        className='h-[52px] w-full pt-[10px] pb-[20px] px-[15px] flex justify-center'
      >
        <div
          style={{
            background: darkTheme
              ? appColors.subTasksBackground
              : appColors['boardBackground'],
          }}
          className='bg-boardBackground w-[358px] h-[32px] p-[2px] rounded-[9px] flex items-center relative'
        >
          <motion.div
            layout
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            style={{
              zIndex: 0,
              boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.04)',
              backgroundColor: darkTheme ? 'black' : 'white',
              position: 'absolute',
              top: '2px',
              bottom: '2px',
              borderRadius: '9px',
              width: `calc(100% / ${items.length} - 4px)`,
              left: `calc(${activeIndex * 50}% + 2px)`,
            }}
          />
          {items.map((item, index) => (
            <div
              key={index}
              style={{
                color: darkTheme ? 'white' : 'black',
              }}
              className='w-full text-bigger h-full flex justify-center items-center cursor-pointer z-10 tracking-sm'
              onClick={() => setActiveIndex(index)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div
        className='w-full h-full flex flex-col overflow-x-hidden relative gap-[20px] p-[20px]'
        {...handlers}
      >
        <motion.div
          className='w-full flex gap-[20px] transition-transform duration-200 ease-in-out'
          style={{
            translateX: activeIndex === 0 ? '0%' : 'calc(-100% - 20px)',
          }}
        >
          <div className='min-w-full h-full flex flex-col'>
            {store.activeMyTasks.length > 0 ? (
              store.activeMyTasks.map((item) => (
                <BoardCard task={item} showProjectName />
              ))
            ) : (
              <div className='min-w-full h-full flex justify-center'>
                <p className='w-fit h-fit text-white text-large font-bold mt-[54px]'>
                  {t('hoorah')}
                </p>
              </div>
            )}
          </div>
          <div className='min-w-full h-full flex flex-col gap-[10px]'>
            <SharingBar />
            {store.allChats.map((item) => (
              <ChatCard item={item} />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};
