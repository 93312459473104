import { SvgIconProps } from '@shared/types/icon.types';

export const PlusIcon = ({ width = 14, height = 14 }: SvgIconProps) => {
  return (
    <svg
      width={width.toString()}
      height={height.toString()}
      viewBox={`0 0 16 16`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 8L15 8M8 15L8 1'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
