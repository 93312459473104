import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic, useStore } from '@hooks/storeHook';
import { CloseDrawerIcon } from '@pages/icons/closeDrawer';
import { observer } from 'mobx-react-lite';
import Drawer from 'react-bottom-drawer';
import { useTranslation } from 'react-i18next';
import { SelectorOfTargetTableProps } from '../types';

export const SelectorOfTargetTable = observer(
  ({ desk, onCloseDrawer, visible }: SelectorOfTargetTableProps) => {
    const { activeDesks } = useStore();
    const { t } = useTranslation();
    const logic = useLogic();

    const onPickNewDesk = async (transferDeskId: number) => {
      await logic.deleteBoard(desk!.id, transferDeskId);
      extraLightHapticFeedback();
      onCloseDrawer();
    };

    return (
      <Drawer onClose={onCloseDrawer} className='drawer' isVisible={visible}>
        <div
          onClick={onCloseDrawer}
          className='absolute right-[15px] top-[15px]'
        >
          <CloseDrawerIcon />
        </div>
        <div className='flex flex-col w-full gap-[15px]'>
          <span className='text-large font-bold w-full text-center'>
            {t('dragTasks')}
          </span>
          {activeDesks
            .filter((e) => e.id !== desk.id)
            .map((desk) => (
              <div
                onClick={() => onPickNewDesk(desk.id)}
                className='w-full flex items-center gap-[5px] border-solid  border-0 border-border-grey border-b-[1px] py-[10px]'
                key={desk.id}
              >
                <span className='text-large font-bold'>{desk.name}</span>
                <span className='text-large text-blue ml-auto cursor-pointer'>
                  {t('button.select')}
                </span>
              </div>
            ))}
        </div>
      </Drawer>
    );
  },
);
