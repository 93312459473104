import { IFile } from '@@types/types';
import DownloadIcon from '@assets/icons/download.svg';
import VideoIcon from '@assets/icons/video.svg';
import { CrossIcon } from '@pages/icons/crossIcon';
import { downloadFile } from '@shared/lib/downloadFile';
import classNames from 'classnames';
import { useState } from 'react';
import { FilePreview } from '../types';

type FileType = File | IFile;

export const VideoPreview = ({
  file,
  handleDeleteFile,
  deletable,
}: FilePreview) => {
  const [isHovered, setIsHovered] = useState(false);

  const isIFile = (file: FileType): file is IFile => {
    return (file as IFile).preview !== undefined;
  };

  const handleDownload = () => {
    if (!isIFile(file)) return;
    downloadFile(file.original_name, file.task_id, file.tg_file_id);
  };

  return (
    <div
      className={classNames(
        'min-w-[100px] max-w-[100px] h-[100px] relative bg-greySlider rounded-[8px] flex transition-all duration-300 ease-in-out',
      )}
      onMouseEnter={() => isIFile(file) && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={VideoIcon}
        alt={`Preview img`}
        className={classNames(
          'w-[40px] m-auto transition-opacity duration-300',
          {
            'opacity-100': !isHovered,
            'opacity-50 blur-sm': isHovered,
          },
        )}
        onClick={handleDownload}
      />
      {isHovered && (
        <div className='backdrop-blur-sm absolute inset-0 rounded-[8px] cursor-pointer'>
          <img
            src={DownloadIcon}
            alt='Download'
            className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[35px]'
            onClick={handleDownload}
          />
        </div>
      )}
      {deletable && <DeleteButton handleDeleteFile={handleDeleteFile} />}
    </div>
  );
};

const DeleteButton = ({
  handleDeleteFile,
}: Pick<FilePreview, 'handleDeleteFile'>) => {
  return (
    <div
      className='rounded-[50%] absolute -top-1 -right-1 bg-grey-text w-5 h-5 text-white cursor-pointer flex justify-center items-center'
      onClick={handleDeleteFile}
    >
      <CrossIcon width={10} height={10} />
    </div>
  );
};
