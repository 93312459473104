import { ITask } from '@@types/types';
import ExpiredIcon from '@assets/icons/expired.png';
import FlameIcon from '@assets/icons/flame.png';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { appColors } from '@config/theme';
import { calcateDeadline } from '@utils/calculateDeadline';

interface IDateCircle {
  task: ITask;
  onClick?: () => void;
}

export const DateCircle = ({ task, onClick }: IDateCircle) => {
  const calculatedDeadline = calcateDeadline(task.deadline);

  const onChangeDateHandler = (
    e: React.MouseEvent<HTMLImageElement | HTMLDivElement, MouseEvent>,
  ) => {
    if (!onClick) return;
    e.stopPropagation();
    extraLightHapticFeedback();
    onClick();
  };

  //если дата не указана
  if (calculatedDeadline === null) {
    // return (
    //   <div
    //     className='inline-flex w-[21px] h-[21px] justify-center items-center mr-[5px] align-middle rotate-90'
    //     onClick={(e) => onChangeDateHandler(e)}
    //   >
    //     <MoreActionsIcon width={21} height={21} />
    //   </div>
    // );
    return null;
  }

  //если дедлайн просрочен
  if (calculatedDeadline === 0) {
    return (
      <div className='inline-flex w-[21px] h-[21px] justify-center align-middle'>
        <img
          src={ExpiredIcon}
          className='w-[17px] h-[19px]'
          onClick={(e) => onChangeDateHandler(e)}
        />
      </div>
    );
  }

  //если задача срочная
  if (task.priority === 1 && calculatedDeadline === 1) {
    return (
      <div
        className='inline-flex w-[21px] h-[21px] justify-center rounded-[100px] items-center align-middle'
        onClick={(e) => onChangeDateHandler(e)}
      >
        <div className='flex min-w-[21px]  min-h-[21px] justify-center items-center'>
          <img src={FlameIcon} width={14} height={18} />
        </div>
      </div>
    );
  }

  //по дефолту круг со значением
  return (
    <div
      style={{
        backgroundColor:
          calculatedDeadline >= 5 ? appColors.blue : appColors.green,
      }}
      className='inline-flex w-[21px] h-[21px] justify-center rounded-[100px] items-center align-middle'
      onClick={(e) => onChangeDateHandler(e)}
    >
      <span className='text-[13px] leading-[0] text-white'>
        {calculatedDeadline}
      </span>
    </div>
  );
};
