import { motion } from 'framer-motion';
import Dropdown, {
  components,
  GroupBase,
  MenuProps,
  Props,
} from 'react-select';

const Menu = (props: MenuProps<any>) => {
  return (
    <motion.div
      initial={{ opacity: 0, translateY: -20 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: -20 }}
      transition={{ duration: 0.1 }}
    >
      <components.Menu {...props}>{props.children}</components.Menu>
    </motion.div>
  );
};

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Dropdown
      {...props}
      components={{ ...props.components, Menu: Menu }}
      classNames={{
        option: () => 'hover:bg-taskInfoBackground transition-all',
        menu: () => 'drop-shadow-[-5px_5px_20px_rgba(0,0,0,0.18)]',
        ...props.classNames,
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          display: 'flex',
          flexWrap: 'nowrap',
          width: 'fit-content',
          height: '28px',
          minHeight: '28px',
          fontSize: '13px',
          border: 'none',
          color: '#007AFF',
          borderRadius: '8px',
          cursor: 'pointer',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#007AFF',
          marginRight: 0,
        }),
        menu: (provided) => ({
          ...provided,
          width: '255px',
          textWrap: 'nowrap',
          fontSize: '13px',
          borderRadius: '12px',
          overflow: 'hidden',
          padding: '0',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        option: (provided) => ({
          ...provided,
          color: 'black',
          cursor: 'pointer',
          padding: '12px 33px 12px 21px',
          backgroundColor: 'transparent',
          fontSize: '15px',
        }),
        menuList: () => ({
          padding: 0,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'white',
          primary50: 'white',
        },
      })}
    />
  );
}
