import { useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { CrossIcon } from '@pages/icons/crossIcon';
import { TaskPage } from '@pages/TaskPage/TaskPage';
import { motion } from 'framer-motion';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

export interface ITaskModal {
  onHide: () => void;
}

export const TaskModal: FC<ITaskModal> = ({ onHide }) => {
  const { selectedTask } = useStore();
  const isTg = useTelegram();
  const { t } = useTranslation();
  const handlers = useSwipeable({
    onSwipedDown: () => onHide(),
    swipeDuration: 0,
    trackMouse: true,
  });

  const initial = {
    tg: {
      translateY: '100vh',
      borderRadius: '40px 40px 0 0',
    },
    desktop: {
      opacity: 0,
    },
  };

  const animate = {
    tg: {
      translateY: '0%',
      borderRadius: '0',
    },
    desktop: {
      opacity: 100,
    },
  };

  const exit = {
    tg: {
      translateY: '100%',
      borderRadius: '40px 40px 0 0',
    },
    desktop: {
      opacity: 0,
    },
  };
  return ReactDOM.createPortal(
    <motion.div
      className={`absolute inset-0 z-[9999] ${!isTg && 'p-8'}`}
      onClick={onHide}
      initial={{
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      animate={{ backgroundColor: 'rgba(0,0,0,.70)' }}
      exit={{
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      transition={{ duration: 0.2 }}
    >
      <motion.section
        className={`bg-white w-full h-full rounded-[20px_20px_0_0] flex flex-col ${!isTg && 'max-w-[872px] mx-auto !rounded-[10px] overflow-hidden'}`}
        onClick={(e) => e.stopPropagation()}
        initial={isTg ? initial.tg : initial.desktop}
        animate={isTg ? animate.tg : animate.desktop}
        exit={isTg ? exit.tg : exit.desktop}
        transition={{ duration: 0.15 }}
        {...handlers}
      >
        {!isTg && (
          <div className='bg-blue min-h-[58px] flex items-center justify-between px-[18px]'>
            <p className='text-white text-[22px]'>
              {selectedTask?.id ? t('editTask') : t('newTask')}
            </p>
            <button
              className='w-fit h-fit m-0 border-none p-0 cursor-pointer bg-transparent'
              onClick={onHide}
            >
              <CrossIcon />
            </button>
          </div>
        )}
        <TaskPage onHide={onHide} />
      </motion.section>
    </motion.div>,
    document.getElementById('root')!,
  );
};
