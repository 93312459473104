import { useStore } from '@hooks/storeHook';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ProjectCard } from './ProjectCard';

export const ProjectMenu = observer(() => {
  const { sortedChats } = useStore();
  const { t } = useTranslation();

  return (
    <aside className='flex flex-col gap-9 min-w-[295px] max-w-[295px] h-full bg-greySecondary py-9 px-[18px] -tracking-[0.4]'>
      <h1 className='text-[36px]'>{t('projects')}</h1>
      <ul className='flex flex-col gap-[6px]'>
        {sortedChats.map((e) => (
          <ProjectCard key={e.id} chat={e} />
        ))}
      </ul>
    </aside>
  );
});
