import { Landing } from '@pages/landing';
import Main from '@pages/main';
import { TasksAndChats } from '@pages/TaskPage/TasksAndChats';

import { NavigateFunction, NavigateOptions } from 'react-router-dom';

let navigateFunc: NavigateFunction | null = null;

export const setNavigate = (navigate: NavigateFunction) => {
  navigateFunc = navigate;
};

export const navigateTo = (route: string, options?: NavigateOptions) => {
  navigateFunc?.(route, options);
};

export enum AppRoutesPaths {
  Boards = '/k',
  Landing = '/',
  AllTasksAndChats = '/all',
}

export type AppRoutesType = {
  name: string;
  path: string;
  element?: JSX.Element;
  childs?: AppRoutesType;
}[];

export const AppRoutes: AppRoutesType = [
  {
    name: 'All',
    path: AppRoutesPaths.AllTasksAndChats,
    element: <TasksAndChats />,
  },
  {
    name: 'Boards',
    path: AppRoutesPaths.Boards,
    element: <Main />,
  },
  {
    name: 'Home',
    path: AppRoutesPaths.Landing,
    element: <Landing />,
  },
];
