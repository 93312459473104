import TaskInfo from '@entities/task';
import { TaskDatePicker } from '@features/taskDatePicker';
import { useLogic } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import { areEqualWithIndexPath } from '@shared/lib/dnd';
import { isChromeOrSafari } from '@shared/lib/isChromeOrSafari';
import { Sortable, SortableItemOverlay } from '@shared/ui/sortable/Sortable';
import React, { useEffect, useRef, useState } from 'react';
import { CardProps, CardType } from '../types';

export const CardContent = React.memo(({ extended, task }: CardProps) => {
  const logic = useLogic();

  const [isInline, setIsInline] = useState(true);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        if (isChromeOrSafari())
          setIsInline(parentRef.current.clientHeight <= 209);
        else setIsInline(parentRef.current.clientHeight <= 167);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [parentRef.current]);

  const fetchTask = async () => {
    if (extended) await logic.loadChatInfo(task.data.chat_id);
    await logic.getTask(task.data.chat_id, task.id!);
  };

  return (
    <div className={`h-full w-full`} onClick={fetchTask}>
      <span
        ref={parentRef}
        className={`${darkTheme ? 'text-white' : 'text-black'} card-text text-[15px] leading-[21px] align-middle select-none line-clamp-[10] hidden text-ellipsis`}
        style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
      >
        <TaskInfo task={task.data} extended={extended} isInline={isInline} />
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TaskDatePicker task={task.data} />
        </span>
      </span>
    </div>
  );
});

export const SortableCard = React.memo(function SortableCard({
  isOverlay,
  item,
  itemIndex,
  laneId,
  laneIndexPath,
}: {
  isOverlay: boolean;
  item: CardType;
  itemIndex: number;
  laneId: number;
  laneIndexPath: number[];
}) {
  const ItemElement = isOverlay ? SortableItemOverlay : Sortable;

  const ctx = {
    parentId: laneId,
    indexPath: [...laneIndexPath, itemIndex],
    data: item,
  };

  return (
    <ItemElement
      ctx={ctx}
      className={`gap-[15px] justify-between rounded-[12px] w-full px-[14px] pt-[7px] pb-[9px] text-[15px] leading-[22px] cursor-pointer ${darkTheme ? 'bg-darkCardBackground' : 'bg-white'}`}
      needAnimate={false}
    >
      <CardContent task={item} />
    </ItemElement>
  );
}, areEqualWithIndexPath);

// export const DraggableCard = React.memo(function SortableCard({
//   isOverlay,
//   item,
//   itemIndex,
//   laneId,
//   laneIndexPath,
// }: {
//   isOverlay: boolean;
//   item: CardType;
//   itemIndex: number;
//   laneId: number;
//   laneIndexPath: number[];
// }) {
//   const ItemElement = isOverlay ? DraggableOverlay : Draggable;

//   const ctx = {
//     parentId: laneId,
//     indexPath: [...laneIndexPath, itemIndex],
//     data: item,
//   };

//   return (
//     <ItemElement
//       ctx={ctx}
//       className={`gap-[15px] justify-between rounded-[12px] w-full px-[14px] pt-[7px] pb-[9px] text-[15px] leading-[22px] cursor-pointer ${darkTheme ? 'bg-darkCardBackground' : 'bg-white'}`}
//     >
//       <CardContent task={item} />
//     </ItemElement>
//   );
// }, areEqualWithIndexPath);
