import { DeskTypes } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { appColors } from '@config/theme';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { SettingsPopup } from '@ui/SettingsPopup/SettingsPopup';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuProps } from '../types';
import { Icon } from './Icon';
import { SelectorOfTargetTable } from './SelectorOfTargetTable';

export const Menu = observer(({ inputRef, desk }: MenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const logic = useLogic();
  const { mergedSortedTasks } = useStore();

  const [isSettingsShow, setIsSettingsShow] = useState(false);
  const [isDeskSelectorShow, setIsDeskSelectorShow] = useState(false);

  const { t } = useTranslation();

  const isTg = useTelegram();

  const tg = window.Telegram.WebApp;

  const onShowSettings = () => {
    setIsSettingsShow(true);
    extraLightHapticFeedback();
  };

  const onHideSettings = () => {
    setIsSettingsShow(false);
  };

  const onHideDateSelector = () => {
    setIsDeskSelectorShow(false);
  };

  const onRename = () => {
    setIsSettingsShow(false);
    const newRef = inputRef as React.RefObject<HTMLInputElement>;
    if (newRef.current) {
      newRef.current.disabled = false;
      newRef.current.focus();
    }
  };

  const deleteBoard = async (flag: boolean) => {
    if (!flag || !desk) return;
    await logic.deleteBoard(desk!.id);
    extraLightHapticFeedback();
  };

  const onRemove = () => {
    if (
      mergedSortedTasks.filter(
        (task) => task.desk_id === desk.id || task.desk?.id === desk.id,
      ).length === 0
    )
      if (isTg) {
        tg.showConfirm(`${t('alert.deleteBoard')} ${desk?.name}?`, deleteBoard);
      } else {
        const res = window.confirm(`${t('alert.deleteBoard')} ${desk?.name}?`);
        res && deleteBoard(true);
      }
    else {
      setIsSettingsShow(false);
      setIsDeskSelectorShow(true);
    }
  };

  const buttons = [
    { title: t('button.rename'), callback: onRename },
    ...(desk.type !== DeskTypes.NEW && desk.type !== DeskTypes.DONE
      ? [
          {
            title: t('button.delete'),
            callback: onRemove,
            textColor: appColors.red,
          },
        ]
      : []),
  ];

  return (
    <>
      <div
        onClick={onShowSettings}
        className='h-[20px] flex justify-center items-center cursor-pointer relative mr-[13px]'
        ref={ref}
      >
        <Icon fill={darkTheme ? 'white' : 'black'} />
      </div>
      <AnimatePresence>
        {isSettingsShow && (
          <SettingsPopup
            refElement={ref.current!}
            onHide={onHideSettings}
            buttons={buttons}
          />
        )}
      </AnimatePresence>
      <SelectorOfTargetTable
        visible={isDeskSelectorShow}
        onCloseDrawer={onHideDateSelector}
        desk={desk}
      />
    </>
  );
});
