import { useStore } from '@hooks/storeHook';
import { RedirectIcon } from '@pages/icons/redirectIcon';
import { telegramChatLink } from '@shared/const/tg';
import { Button, ButtonViews } from '@shared/ui/button';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const RedirectToTgButton = observer(() => {
  const { chatInfo } = useStore();
  const { t } = useTranslation();

  const redirectHandler = () => {
    window.open(telegramChatLink + chatInfo?.tg_id, '_blank');
  };

  return (
    <Button
      view={ButtonViews.FILLED}
      icon={<RedirectIcon width={14} height={14} />}
      onClick={redirectHandler}
      className='!pr-[10px]'
    >
      {t('tgChat')}
    </Button>
  );
});
