import FilledStar from '@assets/icons/filledStar.svg';
import Star from '@assets/icons/star.svg';
import { useLogic, useStore } from '@hooks/storeHook';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { ProjectCardProps } from '../types';

export const ProjectCard = observer(({ chat }: ProjectCardProps) => {
  const logic = useLogic();
  const store = useStore();
  const [isHovered, setIsHovered] = useState(false);

  const toggleOnHovered = (flag: boolean) => {
    setIsHovered(flag);
  };

  const onClick = async () => {
    store.chat_id = chat.id;
    await logic.switchProject();
  };

  const onPinChat = async (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    const isChatPinned = Boolean(chat.is_pinned);
    await logic.updateChat({
      ...chat,
      is_pinned: Number(!isChatPinned) as 0 | 1,
    });
  };

  const isPinned = chat.is_pinned === 1;

  return (
    <li
      className={`h-[50px] bg-transparent relative ${store.chat_id === chat.id ? 'bg-white' : 'hover:bg-greyHover'} rounded-normal flex items-center justify-between px-[10px] cursor-pointer transition-colors duration-300`}
      onClick={onClick}
      onMouseEnter={() => toggleOnHovered(true)}
      onMouseLeave={() => toggleOnHovered(false)}
    >
      <p className='max-w-[80%] whitespace-nowrap overflow-hidden overflow-ellipsis'>
        {chat.title}
      </p>
      {(isHovered || isPinned) && (
        <img src={isPinned ? FilledStar : Star} onClick={(e) => onPinChat(e)} />
      )}
    </li>
  );
});
