import { PropsWithChildren } from 'react';

export const SettingSection = ({
  name,
  children,
}: PropsWithChildren & { name: string }) => (
  <div className='w-full flex flex-col gap-[8px]'>
    <span className='pl-[15px] text-grey-text text-bigger uppercase'>
      {name}
    </span>
    <div className='w-full rounded-[12px] bg-white px-[18px]'>{children}</div>
  </div>
);
