export const isVersionValid = (version: string) => {
  const [major, minor] = version.split('.').map(Number);

  if (major > 8) {
    return true;
  } else if (major === 8) {
    return minor >= 0;
  } else {
    return false;
  }
};
