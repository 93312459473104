import { ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic } from '@hooks/storeHook';
import { CheckBox } from '@pages/icons/checkBox';
import { EnterIcon } from '@pages/icons/enterIcon';
import { Spinner } from '@shared/ui/spinner';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { SubTasks } from './SubTasks';

export interface ITaskAndSubtasksComponent {
  setTask: (field: string, value: ITask[keyof ITask]) => void;
  task: ITask;
}

export const TaskAndSubtasksComponent = observer(
  ({ setTask, task }: ITaskAndSubtasksComponent) => {
    const subtasks = task?.subtasks;
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const logic = useLogic();
    const { t } = useTranslation();

    const subTaskTextRef = useRef<any>();

    const [subTaskTitleInput, setInput] = useState('');

    const onChangeTitle = (e: any) => {
      setTask('title', e.target.value);
    };

    const handleSubTaskTitleInput = (event: any) => {
      setInput(event.currentTarget.value);
    };

    async function handleCreateSubTask() {
      setIsLoading(true);
      await logic.updateTask({
        ...task,
        subtasks: [
          ...task.subtasks,
          {
            id: task.subtasks.length,
            task_id: 0,
            title: subTaskTitleInput,
            status: 'active',
            created_at: '',
            updated_at: '',
          },
        ],
      });
      setIsLoading(false);

      setTask('subtasks', [
        ...task.subtasks,
        {
          id: task.subtasks.length,
          task_id: 0,
          title: subTaskTitleInput,
          status: isChecked ? 'complete' : 'active',
          created_at: '',
          updated_at: '',
        },
      ]);
      extraLightHapticFeedback();

      setInput('');
    }

    return (
      <section className='flex flex-col gap-[19px] pt-[17px] pb-[14px]'>
        <TextareaAutosize
          value={task?.title ?? ''}
          onChange={onChangeTitle}
          minRows={1}
          placeholder={t('input.taskPlaceholder')}
          className='bg-transparent text-large p-0 w-full text-black focus:outline-none !border-none  shadow-none -tracking-[0.4] resize-none'
        />
        <div className='flex flex-col  gap-[15px]'>
          {subtasks?.map((subtask, index) => (
            <SubTasks
              subtask={subtask}
              index={index}
              key={subtask.id}
              setTask={setTask}
              task={task}
            />
          ))}
          <div className='flex w-full items-center'>
            <div
              className='min-w-[20px] cursor-pointer'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsChecked(!isChecked);
              }}
            >
              <CheckBox active={isChecked} />
            </div>
            <div className='flex ml-[10px] w-full items-center'>
              <div className='flex w-full items-center gap-[5px] border-solid  border-0 border-border-grey border-b-[1px] mr-[10px]'>
                <TextareaAutosize
                  value={subTaskTitleInput}
                  onChange={handleSubTaskTitleInput}
                  ref={subTaskTextRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleCreateSubTask();

                      subTaskTextRef.current.selectionStart = 0;
                      subTaskTextRef.current.selectionEnd = 0;
                    }
                  }}
                  disabled={isLoading}
                  minRows={1}
                  maxRows={6}
                  className='w-full text-[15px] bg-transparent text-grey-text focus:outline-none !border-none  shadow-none !p-0 !pb-[2px] tracking-[0] resize-none'
                  placeholder={t('input.subtaskPlaceholder')}
                />
              </div>
            </div>
            <motion.div
              transition={{ duration: 0.7 }}
              animate={{ rotateY: !isLoading ? 0 : 180 }}
              className='min-w-[20px] h-[20px] ml-auto'
              style={{ scale: '(-1, 1)' }}
            >
              <motion.div
                animate={{
                  rotateY: !isLoading ? 0 : 180,
                }}
                onClick={handleCreateSubTask}
                className='ml-auto cursor-pointer h-[20px] absolute l-0 t-0'
                style={{ backfaceVisibility: 'hidden' }}
              >
                <EnterIcon />
              </motion.div>
              <motion.div
                initial={{ rotateY: 180 }}
                animate={{
                  rotateY: !isLoading ? 180 : 0,
                }}
                className='ml-auto cursor-pointer h-[20px] absolute l-0 t-0'
                style={{ backfaceVisibility: 'hidden' }}
              >
                <Spinner width={20} height={20} thickness={2} />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
    );
  },
);
