import { IFile } from '@@types/types';

export interface FilePreview {
  file: File | IFile;
  deletable?: boolean;
  handleDeleteFile?: () => void;
}

export enum FileTypeEnum {
  IMG = 'IMG',
  VIDEO = 'VIDEO',
  DOC = 'DOC',
}

export interface FileType {
  label: string;
  type: FileTypeEnum;
}

export interface AttachFileButtonProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
