import { DndContext } from '@shared/ui/sortable/Sortable';
import { useContext } from 'react';
import { Icon } from './Icon';

export const DeskDragButton = () => {
  const context = useContext(DndContext);

  return (
    <div className='ml-[3px] h-4 cursor-grab' {...context?.listeners}>
      <Icon />
    </div>
  );
};
