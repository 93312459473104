import { IMessage } from '@@types/types';

export const prepareDataForApi = (
  obj: Partial<Pick<IMessage, 'parent_id' | 'text' | 'type'>>,
  images: File[],
) => {
  const formData = new FormData();
  obj.text && formData.append('text', obj.text);
  formData.append('type', String(obj.type));

  images.forEach((img, idx) => {
    formData.append(`files[${idx}]`, img);
  });

  return formData;
};
