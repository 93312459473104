import { LoadState } from '@@types/loadState';
import { NewTaskButton } from '@features/newTaskButton';
import { useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { TelegramHeader } from '@pages/HomePage/components/TelegramHeader';
import { goBack } from '@shared/lib/nav';
import { Layout } from '@shared/ui/layouts';
import { Loader } from '@ui/Loader/Loader';
import { BoardHeader } from '@widgets/boardHeader';
import { ProjectMenu } from '@widgets/projectMenu';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Board } from './Board';

export const Page = observer(() => {
  const { loading } = useStore();
  const isTg = useTelegram();

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    if (!tg) return;

    tg.BackButton.show();

    tg.BackButton.onClick(goBack);

    return () => {
      tg.BackButton.hide();
      tg.BackButton.offClick(goBack);
    };
  }, []);

  const renderLoader = () => (
    <AnimatePresence>
      {loading === LoadState.Loading && !isTg && <Loader />}
    </AnimatePresence>
  );

  return (
    <Layout>
      {!isTg && <ProjectMenu />}
      <motion.div
        className='w-full overflow-y-hidden max-h-[100svh] flex flex-col relative'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        {isTg ? <TelegramHeader /> : <BoardHeader />}
        {renderLoader()}
        <Board />
        {isTg && <NewTaskButton />}
      </motion.div>
    </Layout>
  );
});
