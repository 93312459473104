import { useLogic, useStore } from '@hooks/storeHook';
import { BoardCard } from '@pages/HomePage/BoardCard';
import { Layout } from '@shared/ui/layouts';
import { TabComponent } from '@ui/TabComponent/TabComponent';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatCard } from './ChatCard';

export const TasksAndChats = observer(() => {
  const store = useStore();
  const logic = useLogic();

  const { t } = useTranslation();
  useEffect(() => {
    logic.getAllTasksAndChats();
  }, []);

  return (
    <Layout>
      <motion.div
        className='w-screen h-screen overflow-auto flex flex-col'
        exit={{ x: '-100%' }}
        transition={{ duration: 0.2 }}
      >
        <TabComponent
          items={[
            {
              name: t('myTasks'),
              render: (
                <div className='w-full h-full p-[20px] flex flex-col'>
                  {[].map((item) => (
                    <BoardCard task={item} showProjectName={true} />
                  ))}
                </div>
              ),
            },
            {
              name: t('myProjects'),
              render: (
                <div className='w-full h-full p-[20px] flex flex-col gap-[10px]'>
                  {store.allChats.map((item) => (
                    <ChatCard item={item} />
                  ))}
                </div>
              ),
            },
          ]}
        />
      </motion.div>
    </Layout>
  );
});
