import { darkTheme } from '@hooks/useTelegram';
import { motion } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface ISettingButton {
  title: string;
  callback: () => void;
  textColor?: string;
}

interface ISettingPopup {
  refElement: HTMLElement;
  onHide: () => void;
  buttons: ISettingButton[];
}

export const SettingsPopup: FC<ISettingPopup> = ({
  refElement,
  onHide,
  buttons,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onHide();
      }
    };

    const timer = setTimeout(() => {
      document.addEventListener('click', handleClickOutside);
    }, 0);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onHide]);

  return ReactDOM.createPortal(
    <motion.div
      ref={popupRef}
      className={`absolute top-[35px] -right-[14px] text-[15px]  bg-white z-[99] rounded-[12px] drop-shadow-[-5px_5px_20px_rgba(0,0,0,0.18)] min-w-[255px] flex flex-col ${darkTheme && '!bg-darkCardBackground'}`}
      initial={{ opacity: 0, translateY: -20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.1 }}
    >
      {buttons.map((e, i) => (
        <>
          <p
            className={`p-0 m-0 cursor-pointer px-[16px] py-[13px] ${darkTheme && 'text-white'} hover:bg-taskInfoBackground`}
            onClick={e.callback}
            style={{ color: e.textColor }}
          >
            {e.title}
          </p>
          {i !== buttons.length - 1 && (
            <div className='border-0 border-t-[0.5px] border-solid border-[#d9d9d9] w-[calc(100%_-_16px)] self-end' />
          )}
        </>
      ))}
    </motion.div>,
    refElement,
  );
};
