import { DocumentPreview } from '@features/commentInput/components/DocumentPreview';
import { ImagePreview } from '@features/commentInput/components/ImagePreview';
import { VideoPreview } from '@features/commentInput/components/VideoPreview';
import { useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import {
  getFullName,
  highlightLinkInString,
  removeHtmlTags,
} from '@shared/lib/stringUtils';
import moment from 'moment';
import { CommentProps } from '../types';

export const Comment = ({ message }: CommentProps) => {
  const { chatInfo, user_id } = useStore();

  const isTg = useTelegram();

  const user = chatInfo?.users.find((e) => e.id === message.user_id);

  const isMyComment = user && user?.id === user_id;

  const useClassName = () => {
    if (isMyComment) return 'right_bubble';
    else if (user && !isMyComment) return 'left_bubble';
    else return `bubble`;
  };

  const openTelegramProfile = () => {
    isTg
      ? window.Telegram.WebApp.openTelegramLink(
          `https://t.me/${user!.username}`,
        )
      : window.open(`tg://resolve?domain=${user!.username}`);
  };

  return (
    <div
      className={`relative ${useClassName()} p-[7px_12px] text-[15px] leading-normal`}
      style={
        {
          '--bg-color': isMyComment
            ? '#EFFDDE'
            : darkTheme || isTg
              ? '#FFF'
              : '#F1F1F4',
        } as React.CSSProperties
      }
    >
      {user && (
        <p
          className='text-blue text-[14px] leading-4 font-medium cursor-pointer'
          onClick={openTelegramProfile}
        >
          {getFullName(user.first_name, user.last_name)}
        </p>
      )}
      <div className='flex flex-col overflow-x-auto'>
        {message.files.length > 0 && (
          <div className='flex gap-4 max-w-full overflow-x-auto overflow-hidden mt-2 pb-1'>
            {message.files.map((file) =>
              file.type === 'photo' ? (
                <ImagePreview file={file} key={file.id} />
              ) : file.type === 'video' ? (
                <VideoPreview file={file} key={file.id} />
              ) : (
                <DocumentPreview file={file} key={file.id} />
              ),
            )}
          </div>
        )}
        <div className='flex gap-2 w-full justify-between overflow-hidden'>
          <p>{highlightLinkInString(removeHtmlTags(message.text))}</p>
          <p className='italic text-[11px] text-[#8E8E93] h-fit self-end leading-[11px] '>
            {moment(message.created_at).format('HH:MM')}
          </p>
        </div>
      </div>
    </div>
  );
};
