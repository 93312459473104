import cn from 'classnames';
import styles from './styles.module.css';
import { ButtonProps } from './types';

export const Button = ({ view, icon, children, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={cn(
        'cursor-pointer flex active:scale-[0.95] p-0 rounded-[8px] h-7 items-center bg-white px-2 transition-all',
        styles[view],
        props.className,
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <p
        className={cn(
          'h-full content-center  border-0',
          styles.label,
          icon && '!border-l',
        )}
      >
        {children}
      </p>
    </button>
  );
};
