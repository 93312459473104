export const removeHtmlTags = (str: string) => {
  return str.replace(/<[^>]*>/g, '');
};

export const getFullName = (firstName: string, lastName: string | null) => {
  if (!lastName) return firstName;
  return firstName + ' ' + lastName;
};


export const highlightLinkInString = (str: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  
  if (!str) return <span>{str}</span>;

  const parts = str.split(urlRegex);
  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <a
          key={index}
          href={part}
          target='_blank'
          rel='noopener noreferrer'
          className='text-blue'
          onClick={(e) => e.stopPropagation()}
        >
          {part}
        </a>
      );
    }
    return <span key={index}>{part}</span>;
  });
};

