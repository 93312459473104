import MonitorIcon from '@assets/icons/monitor.svg';
import { appColors, getRandomColor } from '@config/theme';
import { ChatPushSelector } from '@features/chatPushSelector';
import { useStore } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import { Button, ButtonViews } from '@shared/ui/button';

export const TelegramHeader = () => {
  const { chatInfo } = useStore();

  const platform = window.Telegram.WebApp.platform;

  const onOpenWebVersion = () => {
    const tg = window.Telegram.WebApp;

    const decoded = tg.initData;

    tg.openLink(
      process.env.REACT_APP_SITE_URL +
        `k?isBrowser=true&chatId=${chatInfo?.id ?? 0}#${decoded}`,
    );
  };

  const projectName = () => (
    <>
      <div
        style={{ backgroundColor: getRandomColor() }}
        className='min-w-[24px] h-[24px] rounded-[100px] font-bold flex justify-center items-center'
      >
        <span className='text-[12px] text-white'>
          {chatInfo?.title[0].toUpperCase()}
        </span>
      </div>
      <span
        style={{ color: darkTheme ? 'white' : 'black' }}
        className='text-large whitespace-nowrap overflow-hidden text-ellipsis'
      >
        {chatInfo?.title}
      </span>
    </>
  );

  return (
    <div
      style={{
        background: darkTheme ? appColors.darkCardBackground : 'white',
      }}
      className='w-full p-[15px] flex items-center gap-[7px]'
    >
      {projectName()}

      <div className='ml-auto flex gap-3'>
        <ChatPushSelector />
        {platform !== 'android' && platform !== 'ios' && (
          <Button
            view={ButtonViews.FILLED}
            className='rounded-normal w-[50px] py-1 px-3 flex !bg-blue justify-center items-center leading-[0]'
            onClick={onOpenWebVersion}
          >
            <img src={MonitorIcon} alt='Open in desktop' className='h-[15px]' />
          </Button>
        )}
      </div>
    </div>
  );
};
