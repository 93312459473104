import { ISubTask, ITask, TaskStatus } from '@@types/types';
import CloseIcon from '@assets/icons/closeRedIcon.svg';
import { CheckBox } from '@pages/icons/checkBox';
import { FC, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic } from '@hooks/storeHook';
import { EnterIcon } from '@pages/icons/enterIcon';
import { Spinner } from '@shared/ui/spinner';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ITaskAndSubtasksComponent } from './TaskAndSubTasksComponent';

export interface SubTaskProps extends ITaskAndSubtasksComponent {
  subtask: ISubTask;
  index: number;
}

export const SubTasks: FC<SubTaskProps> = ({ subtask, setTask, task }) => {
  const [subtaskText, setSubtaskText] = useState(subtask.title);
  const [isSubtaskEdited, setIsSubtaskEdited] = useState(false);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const updateTask = async (editedTask: ITask) => {
    setIsLoading(true);
    await logic.updateTask(editedTask);
    setIsLoading(false);
  };

  const logic = useLogic();

  useEffect(() => {
    subtaskText === subtask.title
      ? setIsSubtaskEdited(false)
      : setIsSubtaskEdited(true);
  }, [subtask, subtaskText]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const updateStatus = async () => {
    let status: TaskStatus;
    if (subtask.status === 'active') {
      status = 'complete';
    } else {
      status = 'active';
    }

    const newSubtasks = task.subtasks.map((e) =>
      e.id === subtask.id ? { ...subtask, status: status } : e,
    );

    setTask('subtasks', newSubtasks);
    updateTask({
      ...task,
      subtasks: newSubtasks,
    });
    extraLightHapticFeedback();
  };

  const onChangeSubtask = (str: string) => {
    setSubtaskText(str);
  };

  const onSaveEditedSubtask = () => {
    const newSubtasks = task.subtasks.map((e) =>
      e.id === subtask.id ? { ...subtask, title: subtaskText } : e,
    );
    setTask('subtasks', newSubtasks);

    updateTask({ ...task, subtasks: newSubtasks });
    extraLightHapticFeedback();
  };

  const deleteSubTask = () => {
    const newSubtasks = task.subtasks.filter((e) => e.id !== subtask.id);

    setTask('subtasks', newSubtasks);

    updateTask({ ...task, subtasks: newSubtasks });
    extraLightHapticFeedback();
  };

  return (
    <div className='w-full h-full flex flex-col gap-[20px]'>
      <div className='flex items-start w-full'>
        <div className='h-[20px] cursor-pointer' onClick={updateStatus}>
          <CheckBox active={subtask.status !== 'active'} />
        </div>
        <TextareaAutosize
          ref={textAreaRef}
          value={subtaskText}
          onChange={(e) => onChangeSubtask(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSaveEditedSubtask();
              textAreaRef.current?.blur();
            }
          }}
          onBlur={() => {
            onSaveEditedSubtask();
          }}
          minRows={1}
          maxRows={6}
          className={`w-full mx-[10px] text-[15px] bg-transparent focus:outline-none !border-none shadow-none !p-0 !pb-[2px] tracking-[0] ${subtask.status !== 'active' ? 'line-through text-grey-text' : ''} resize-none`}
          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
          placeholder={t('input.subtaskPlaceholder')}
          disabled={isLoading}
        />
        <motion.div
          transition={{ duration: 0.7 }}
          animate={{ rotateY: !isLoading && isSubtaskEdited ? 0 : 180 }}
          className='relative min-w-[20px] h-[20px]'
          style={{ scale: '(-1, 1)' }}
        >
          <motion.div
            animate={{
              rotateY: !isLoading && isSubtaskEdited ? 0 : 180,
            }}
            onClick={onSaveEditedSubtask}
            className='ml-auto cursor-pointer h-[20px] absolute l-0 t-0'
            style={{ backfaceVisibility: 'hidden' }}
          >
            <EnterIcon />
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{
              rotateY: !isLoading && isSubtaskEdited ? 180 : 0,
            }}
            onClick={isLoading ? undefined : deleteSubTask}
            className='ml-auto cursor-pointer h-[20px] absolute l-0 t-0'
            style={{ backfaceVisibility: 'hidden' }}
          >
            {isLoading ? (
              <Spinner width={20} height={20} thickness={2} />
            ) : (
              <img src={CloseIcon} />
            )}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};
