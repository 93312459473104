import { appColors } from '@config/theme';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { SliderProps } from './types';

export const Slider = ({ slides, className, dark = false }: SliderProps) => {
  return (
    <div
      className={classNames(
        'bg-greySlider min-h-[32px] p-[2px] rounded-[9px] flex items-center relative',
        className,
      )}
    >
      <motion.div
        layout
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        style={{
          zIndex: 0,
          boxShadow: '0px 3px 1px 0px rgba(0, 0, 0, 0.04)',
          backgroundColor: dark ? appColors.darkCardBackground : 'white',
          position: 'absolute',
          top: '2px',
          bottom: '2px',
          borderRadius: '9px',
          width: `calc(100% /${slides.length} - 4px)`,
          left: `calc(${slides.findIndex((s) => s.active) * (100 / slides.length)}% + 2px)`,
        }}
      />
      {slides.map((e, idx) => {
        if (typeof e.content === 'string') {
          return (
            <div
              key={idx}
              className='w-full text-bigger h-full flex justify-center items-center cursor-pointer z-10 tracking-sm text-black'
              onClick={() => e.onClick()}
            >
              {e.content}
            </div>
          );
        } else {
          return (
            <div
              key={idx}
              className='w-full text-bigger h-full flex justify-center items-center z-10 tracking-sm text-black cursor-pointer'
              onClick={() => e.onClick()}
            >
              {e.content}
            </div>
          );
        }
      })}
    </div>
  );
};
