import { ITask } from '@@types/types';
import { ArrowRight } from '@pages/icons/arrowRight';
import { CalendarIcon } from '@pages/icons/calendarIcon';
// import { CloseDrawerIcon } from '@components/icons/closeDrawer';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useTelegram } from '@hooks/useTelegram';
import { DateCircle } from '@ui/DateCircle/DateCircle';
import { DateSelector } from '@ui/EntitySelectors/DateSelector/DateSelector';
import { MiniModal } from '@ui/MiniModal/MiniModal';
import { calcateDeadline } from '@utils/calculateDeadline';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerDrawer } from './DatePickerDrawer';
export const DateSelectButton = ({
  setTask,
  task,
}: {
  setTask: (field: ITask) => void;
  task: ITask;
}) => {
  const isTg = useTelegram();
  const ref = useRef<HTMLDivElement>(null);
  const [parentElement, setParentElement] = useState<Element | null>(null);
  const { t } = useTranslation();

  const calculatedDeadline = calcateDeadline(task.deadline);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const tg = window.Telegram?.WebApp;

  useEffect(() => {
    setParentElement(ref.current);
  }, []);

  const onOpenDrawer = () => {
    tg.MainButton.hide();

    setTimeout(() => {
      setIsDatePickerVisible(true);
      extraLightHapticFeedback();
    }, 200);
  };

  const onCloseDrawer = () => {
    setIsDatePickerVisible(false);
    extraLightHapticFeedback();

    setTimeout(() => {
      tg.MainButton.show();
    }, 200);
  };

  const onSaveDateHandler = (date: Date | null, priority?: true) => {
    if (date)
      setTask({
        ...task,
        deadline: date ? moment(date).format('YYYY-MM-DD') : null,
        priority: priority ? 1 : 0,
      });

    setIsDatePickerVisible(false);
    extraLightHapticFeedback();

    setTimeout(() => {
      tg.MainButton.show();
    }, 200);
  };

  return (
    <>
      <div
        onClick={onOpenDrawer}
        className='flex gap-[15px] items-center w-full h-[46px] border-solid  border-0 border-border-grey border-b-[1px] relative cursor-pointer'
        ref={ref}
      >
        <CalendarIcon />
        <span className='text-base vsm:text-large'>{t('date')}</span>
        <div className='flex items-center ml-auto gap-[16px]'>
          {calculatedDeadline !== null && <DateCircle task={task} />}
          {
            <span className='text-base vsm:text-large text-grey-text'>
              {task.deadline
                ? moment(task.deadline).format('DD MMMM')
                : t('button.select')}
            </span>
          }
          <ArrowRight />
        </div>
      </div>

      {parentElement && isDatePickerVisible && !isTg && (
        <MiniModal
          parent={parentElement}
          direction='right'
          headerTitle={t('dueDate')}
          onHide={onCloseDrawer}
          body={<DateSelector task={task} onSave={onSaveDateHandler} />}
          classname='w-[390px]'
        />
      )}

      {isTg && (
        <DatePickerDrawer
          isVisible={isDatePickerVisible}
          task={task}
          onSaveDateHandler={onSaveDateHandler}
          onCloseDrawer={onCloseDrawer}
        />
      )}
    </>
  );
};
