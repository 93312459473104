import { appColors } from '@config/theme';
import './styles.css';
import { SpinnerProps } from './types';

export const Spinner = ({
  className,
  width = 32,
  height = 32,
  thickness = 4,
  color = appColors.blue,
}: Partial<SpinnerProps>) => {
  return (
    <span
      className={`spinner ${className}`}
      style={{
        width: width,
        height: height,
        border: `${thickness}px solid ${color}`,
        borderBottomColor: '#FFF',
      }}
    />
  );
};
