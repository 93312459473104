import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme } from '@hooks/useTelegram';
import { forwardRef, Ref, useRef, useState } from 'react';
import { InputProps } from '../types';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ desk }: InputProps, ref: Ref<HTMLInputElement>) => {
    const { renameKey } = useStore();
    const logic = useLogic();

    const [newTitle, setNewTitle] = useState(desk.name);
    const isSending = useRef(false);

    const onChangeNewTitleHandler = (str: string) => {
      setNewTitle(str);
    };

    const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      e.preventDefault();
      sendTitle();
    };

    const onBlurHandler = () => {
      if (newTitle.length === 0) setNewTitle(desk.name);
      if (!isSending.current) {
        sendTitle();
      }
    };

    const sendTitle = async () => {
      if (newTitle.length === 0 || desk.name === newTitle) return;
      isSending.current = true;
      const inputRef = ref as React.RefObject<HTMLInputElement>;
      if (inputRef.current) {
        inputRef.current.blur();
        inputRef.current.disabled = true;
      }
      await logic.updateBoard({ ...desk!, name: newTitle });
      extraLightHapticFeedback();
      renameKey(desk.name, newTitle);
      isSending.current = false;
    };

    return (
      <input
        ref={ref}
        disabled={true}
        value={newTitle}
        onChange={(e) => onChangeNewTitleHandler(e.target.value)}
        onBlur={onBlurHandler}
        onKeyDown={onPressEnter}
        style={{ color: darkTheme ? 'white' : 'black' }}
        className={`deskTitle max-w-[180px] text-large font-bold bg-transparent border-none ml-[11px] pr-2 py-[3.5px] rounded-[10px] focus:outline-none focus:bg-transparent transition-colors duration-[250ms]`}
      />
    );
  },
);
