import { SvgIconProps } from '@shared/types/icon.types';

export const EnterIcon = ({ width = 20, height = 20 }: SvgIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='20' height='20' rx='10' fill='#007AFF' fillOpacity='0.1' />
    <path
      d='M14 6C14 10 10 12 6 12M6 12L8 9M6 12L9 14'
      stroke='#007AFF'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
