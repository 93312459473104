import { ChatPushTypesEnum } from '@features/chatPushSelector/types';

export type TaskStatus = 'active' | 'complete' | 'canceled';

export type ChatType = 'private' | 'group';

export enum DeskTypes {
  'NEW' = 1,
  'IN_WORK' = 2,
  'ON_CHECK' = 3,
  'DONE' = 4,
}

export interface IUser {
  created_at: string;
  first_name: string;
  last_name: string;
  id: number;
  photo_url: string;
  updated_at: string;
  username: string;
}
export interface IDesk {
  id: number;
  name: string;
  position: number;
  deleted: 0 | 1;

  is_default: number;
  type: DeskTypes;
}

export interface IFile {
  created_at: string;
  id: number;
  original_name: string;
  preview: string;
  task_id: number;
  tg_download_path: string;
  tg_file_id: string;
  tg_file_path: string;
  message_id: number;
  type: 'photo' | 'video' | 'document';
}

export type IChatInfo = IChat & {
  users: IUser[];
  desks: IDesk[];
};

export type IAllTasks = {
  [id: string]: ITask[];
};

export interface IChat {
  id: number;
  tg_id: number;
  title: string;
  type: ChatType;
  created_at: string;
  push_type: ChatPushTypesEnum;
  updated_at: string;
  is_pinned: 1 | 0;
}

export interface IMessage {
  created_at: string;
  id: number;
  parent_id: number | null;
  task_id: number;
  text: string;
  type: number;
  updated_at: string;
  user_id: number;
  files: IFile[];
}

export interface ITask {
  id: number | null;
  chat_id: number;
  creator_id: number;
  title: string | null;
  status: 0 | 1 | 2;
  deadline: string | null;
  subtasks: ISubTask[];
  number: number;
  created_at: string;
  updated_at: string;
  responsible: IUser[];
  priority: number;
  file: IFile[];
  desk?: IDesk;
  desk_id?: number;
  newFiles?: { preview: string | null; blob: File; created_at: string }[];
  messages: IMessage[];
}

export interface ISubTask {
  id: number;
  task_id: number;
  title: string;
  status: TaskStatus;
  created_at: string;
  updated_at: string;
}

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  created_at: string;
  updated_at: string;
}
