import { useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { PlusIcon } from '@pages/icons/plusIcon';
import { Button, ButtonViews } from '@shared/ui/button';
import { useTranslation } from 'react-i18next';
import { NewTaskButtonProps } from '../types';
import { Icon } from './Icon';

export const NewTaskButton = ({ insideDesk, deskId }: NewTaskButtonProps) => {
  const isTg = useTelegram();
  const { setEmptyTask } = useStore();
  const { t } = useTranslation();

  const onAddTaskHandler = () => {
    setEmptyTask(deskId);
  };

  if (insideDesk) {
    return (
      <button
        onClick={onAddTaskHandler}
        className={`border-none w-fit h-fit flex p-0 cursor-pointer bg-transparent ${darkTheme ? 'text-white' : 'text-black'}`}
      >
        <PlusIcon />
      </button>
    );
  }

  return isTg ? (
    <button
      onClick={onAddTaskHandler}
      className='absolute bottom-[35px] right-[35px] bg-transparent border-none cursor-pointer'
    >
      <Icon />
    </button>
  ) : (
    <Button
      view={ButtonViews.FILLED}
      icon={<PlusIcon />}
      onClick={onAddTaskHandler}
      className='!pr-[10px]'
    >
      {t('task')}
    </Button>
  );
};
