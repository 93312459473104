import { appColors } from '@config/theme';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { BellIcon } from '@pages/icons/bellIcon';
import { Slider } from '@shared/ui/slider';
import { Slide } from '@shared/ui/slider/types';
import clx from 'classnames';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatPushTypesEnum, PushOptions } from '../types';

const pushOptions: PushOptions = [
  {
    label: 'all',
    value: ChatPushTypesEnum.ACCESS,
    icon: (
      <BellIcon type='default' fill={appColors.blue} width={13} height={15} />
    ),
  },
  {
    label: 'important',
    value: ChatPushTypesEnum.CD,
    icon: <BellIcon type='important' width={13} height={15} />,
  },
];

export const ChatPushSelector = observer(() => {
  const { chatInfo } = useStore();
  const logic = useLogic();
  const { t } = useTranslation();

  const onChangePushTypeHandler = async (value: ChatPushTypesEnum) => {
    //@ts-ignore
    await logic.updateChatInfo({
      ...chatInfo,
      push_type: value!,
    });
  };

  const slides: Slide[] = [
    {
      active: chatInfo?.push_type === pushOptions[1].value,
      content: (
        <SlideContent
          text={t('notifications.' + pushOptions[1].label)}
          icon={pushOptions[1].icon}
          active={chatInfo?.push_type === pushOptions[1].value}
        />
      ),
      onClick: () => onChangePushTypeHandler(pushOptions[1].value),
    },
    {
      active: chatInfo?.push_type === pushOptions[0].value,
      content: (
        <SlideContent
          text={t('notifications.' + pushOptions[0].label)}
          icon={pushOptions[0].icon}
          active={chatInfo?.push_type === pushOptions[0].value}
        />
      ),
      onClick: () => onChangePushTypeHandler(pushOptions[0].value),
    },
  ];

  return <Slider slides={slides} className='!min-h-[28px]' dark={darkTheme} />;
});

interface SlideContent {
  text: string;
  icon: ReactNode;
  active: boolean;
}

const SlideContent = ({ icon, text, active }: SlideContent) => {
  const isTg = useTelegram();
  return (
    <div
      className={clx('flex gap-2 w-24 items-center justify-center', {
        'font-semibold': active,
        '!w-11': isTg,
      })}
    >
      {icon}
      {!isTg && <p>{text}</p>}
    </div>
  );
};
