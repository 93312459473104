export enum ChatPushTypesEnum {
  DENY = 1,
  CD = 2, // CREATE AND DELETE
  ACCESS = 3,
}

export type PushOption = {
  label: string;
  value: ChatPushTypesEnum;
  icon: JSX.Element;
};

export type PushOptions = PushOption[];
