import { SvgIconProps } from '@shared/types/icon.types';

export const CrossIcon = ({ width = 21, height = 21 }: SvgIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.1583 1.28033C20.4512 0.987437 20.4512 0.512563 20.1583 0.21967C19.8654 -0.0732233 19.3905 -0.0732233 19.0976 0.21967L10.1889 9.12833L1.28035 0.219745C0.987459 -0.0731482 0.512586 -0.0731482 0.219693 0.219745C-0.0732006 0.512638 -0.0732006 0.987512 0.219693 1.28041L9.12828 10.189L0.21967 19.0976C-0.073224 19.3905 -0.0732229 19.8654 0.21967 20.1583C0.512564 20.4512 0.987436 20.4512 1.28033 20.1583L10.1889 11.2497L19.0976 20.1583C19.3905 20.4512 19.8654 20.4512 20.1583 20.1583C20.4512 19.8654 20.4512 19.3906 20.1583 19.0977L11.2496 10.189L20.1583 1.28033Z'
      fill='white'
    />
  </svg>
);
